import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  productContent: {
    display: "flex",
    flexWrap: "wrap",
    // margin: "8px 5px 0",
    paddingBottom: 70,
    marginTop: 100,
    marginBottom: -100,
  },
  wrapper: {
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 15,
    "@media(min-width: 280px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 320px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 360px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 400px)": {
      width: "calc(100%/2)",
      height: "100%",
    },
    "@media(min-width: 600px)": {
      width: "calc(100%/3)",
      height: "100%",
    },
    "@media(min-width: 960px)": {
      width: "calc(100%/4)",
      height: "100%",
    },
  },
  card: {
    height: "100%",
    borderRadius: 5,
    boxShadow: "0px 0px 8px rgba(0,0,0,0.2)",
  },
  img: {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "top",
    "@media(min-width: 280px)": {
      height: 128,
    },
    "@media(min-width: 320px)": {
      height: 148,
    },
    "@media(min-width: 360px)": {
      height: 168,
    },
    "@media(min-width: 960px)": {
      height: 270,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 15px",
    marginTop: 8,
  },
  title: {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "center",
    color: "#333333",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "@media(min-width: 280px)": {
      fontSize: 12,
    },
    "@media(min-width: 320px)": {
      fontSize: 12,
    },
    "@media(min-width: 360px)": {
      fontSize: 14,
    },
    "@media(min-width: 960px)": {
      fontSize: 14,
    },
  },
  price: {
    fontWeight: 700,
    fontSize: 12,
    color: "#eb6669",
    textAlign: "center",
    maxWidth: 139,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media(max-width: 480px)": {
      fontSize: 8,
      width: 100,
    },
    "@media(min-width: 768px)": {
      maxWidth: 150,
    },
  },
  btnWrap: {
    margin: 8,
    marginBottom: 12,
  },
  btn: {
    color: "#ffff",
    fontWeight: 700,
    fontSize: 12,
    "@media(max-width: 280px)": {
      padding: "4px 4px",
    },
    textTransform: "capitalize",
  },
  empty: {
    backgroundColor: "hsla(0,0%,100%,.9)",
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  txtEmpty: {
    fontSize: 18,
    fontWeight: 700,
    color: "rgba(0,0,0,.4)",
  },
  // appBar: {
  //   position: 'relative',
  // },
  titleDialog: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    overflowX: "hidden",
  },
  btnt: {
    padding: "4px 16px",
    minWidth: "auto",
  },
  btnSelect: {
    borderRadius: 100,
    marginRight: 10,
    marginBottom: 16,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  row: {
    display: "flex",
    overFlow: "hidden",
    overflowX: "auto",
  },
  carousel: {
    width: "100%",
    height: 360,
    borderBottom: "1px solid #E5E5E5",
  },
  head: {
    padding: 16,
  },
  label: {
    backgroundColor: "#e82868",
    fontSize: 14,
    lineHeight: "20px",
    color: "#fff",
    display: "inline-block",
    padding: "0 6px",
    borderRadius: 4,
    marginBottom: 4,
  },
  priceWrapper: {
    display: "flex",
    padding: "16px 0px",
  },
  sectionPrice: {
    width: "50%",
    padding: "0px 16px",
  },
  titlePrice: {
    color: "rgb(136, 136, 136)",
    "@media(max-width: 480px)": {
      fontSize: 13,
    },
    "@media(max-width: 375px)": {
      fontSize: 12,
    },
  },
  descPrice: {
    display: "flex",
    flexWrap: "wrap",
    "@media(max-width: 480px)": {
      fontSize: 13,
    },
    "@media(max-width: 375px)": {
      fontSize: 10,
    },
  },
  descHead: {
    textDecoration: "none",
    padding: "12px 16px",
    display: "flex",
    justifyContent: "space-between",
    color: "inherit",
    "@media(max-width: 480px)": {
      fontSize: 13,
    },
    "@media(max-width: 320px)": {
      fontSize: 10,
    },
  },
  descBody: {
    padding: "0px 16px 16px",
    whiteSpace: "pre-line",
    "@media(max-width: 480px)": {
      fontSize: 13,
    },
    "@media(max-width: 320px)": {
      fontSize: 10,
    },
  },
  btnWrapper: {
    padding: 16,
  },
  variantContent: {
    padding: 16,
  },
  h3Title: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "18px",
    marginBottom: 10,
    color: "#888",
    textTransform: "capitalize",
  },
  loading: {
    width: "100%",
    height: "calc(100vh - 60px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))

export default useStyles
