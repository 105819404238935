import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Divider from "@material-ui/core/Divider"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Slide from "@material-ui/core/Slide"
import useStyles from "../styles"
import { arrayToCurrencyString, reduceVariants } from "../utils"
import { uniqBy } from "lodash"
import Carousel from "nuka-carousel"
import { NavigateBefore, NavigateNext } from "@material-ui/icons"
import Gap from "../components/Gap"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function DialogDetail({ open, handleClose, product }) {
  const classes = useStyles()

  const [variantIdSelected, setVariantIdSelected] = useState()

  const [variant1, setVariant1] = useState()

  const [variant1Deps, setVariant1Deps] = useState()

  const [variant2, setVariant2] = useState()

  const [variant2Deps, setVariant2Deps] = useState()

  const [variantSelected, setVariantSelected] = useState({})

  const onClose = () => {
    handleClose()
    setVariantIdSelected()
    setVariant1()
    setVariant1Deps()
    setVariant2()
    setVariant2Deps()
    setVariantSelected()
  }

  useEffect(() => {
    if (variantIdSelected) {
      const findVariant = product?.variants?.find(
        variant => variant.id === variantIdSelected
      )

      if (findVariant) {
        setVariantSelected(findVariant)
      } else {
        setVariantSelected({})
      }
    } else {
      setVariantSelected({})
    }
  }, [variantIdSelected, product])

  const handleChangeVariant1 = (value, deps) => e => {
    if (variant1 === value) {
      setVariant1()
      setVariant2Deps()
      setVariant2()
      setVariantIdSelected()
    } else {
      setVariant1(value)
      setVariant2()
      setVariantIdSelected()
      setVariant2Deps(deps)
    }
  }

  const handleChangeVariant2 = (value, deps) => e => {
    if (variant2 === value) {
      setVariant2()
      setVariant1()
      setVariant1Deps()
      setVariantIdSelected()
    } else {
      setVariant2(value)
      setVariant1()

      setVariantIdSelected()
      setVariant1Deps(deps)
    }
  }

  const chooseVariantIDFromVariant1 = (value, id) => e => {
    if (variant1 === value) {
      setVariant1()
      setVariantIdSelected()
    } else {
      setVariant1(value)
      setVariantIdSelected(id)
    }
  }

  const chooseVariantIDFromVariant2 = (value, id) => e => {
    if (variant2 === value) {
      setVariant2()
      setVariantIdSelected()
    } else {
      setVariant2(value)
      setVariantIdSelected(id)
    }
  }

  const productThumbnails =
    product?.thumbnail?.reduce((prev, curr, index) => {
      return [
        ...prev,
        {
          key: product?.id + "_" + index,
          id: product?.id,
          thumbnail: curr,
          type: "product",
        },
      ]
    }, []) ?? []

  const allVariantThumbnails =
    product?.variants?.reduce((prev, curr) => {
      const currentThumbs =
        curr.thumbnail?.map((thumb, index) => ({
          key: curr.id + "_" + index,
          id: curr.id,
          thumbnail: thumb,
          type: "variant",
        })) ?? []
      return [...prev, ...currentThumbs]
    }, []) ?? []

  const notUniqthumbnails = variantIdSelected
    ? variantSelected?.thumbnail?.length > 0
      ? variantSelected?.thumbnail?.map((thumb, index) => {
          return {
            key: variantIdSelected + "_" + index,
            id: variantIdSelected,
            thumbnail: thumb,
            type: "variant",
          }
        }) ?? []
      : [
          {
            key: variantIdSelected + "_" + 1,
            id: variantIdSelected,
            thumbnail:
              "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg",
            type: "variant",
          },
        ]
    : [...productThumbnails, ...allVariantThumbnails]

  const thumbnails = uniqBy(notUniqthumbnails, value => value.thumbnail)

  const formattedVariants = reduceVariants(product?.variants ?? [])

  const all_harga_normal = variantIdSelected
    ? variantSelected?.harga_normal
    : product?.variants?.map(variant => variant[`harga_normal`]) ?? []

  const all_harga_str = arrayToCurrencyString(all_harga_normal)

  const stok_available = variantIdSelected
    ? variantSelected.stok_available
    : product?.variants?.reduce((prev, curr) => {
        const stok = curr.stok_available ? curr.stok_available : 0
        return prev + stok
      }, 0) ?? 0

  const sku = variantIdSelected ? variantSelected.sku : product?.brand?.nama

  //   console.log(product)

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar style={{position: 'relative'}} className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <ArrowBackIcon />
              {/* <CloseIcon /> */}
            </IconButton>
            <Typography variant="h6" className={classes.titleDialog}>
              {product?.nama}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={onClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <Carousel
            className={classes.carousel}
            renderCenterLeftControls={({ previousSlide, currentSlide }) =>
              currentSlide !== 0 ? (
                <IconButton size="small" onClick={previousSlide}>
                  <NavigateBefore fontSize="large" />
                </IconButton>
              ) : null
            }
            renderCenterRightControls={({
              nextSlide,
              slideCount,
              currentSlide,
            }) =>
              currentSlide !== slideCount - 1 ? (
                <IconButton size="small" onClick={nextSlide}>
                  <NavigateNext fontSize="large" />
                </IconButton>
              ) : null
            }
          >
            {thumbnails.map((item, index) => {
              return (
                <img
                  key={index}
                  src={item.thumbnail}
                  alt={item.key}
                  style={{
                    objectFit: "contain",
                    width: 360,
                    height: 360,
                    margin: "0 auto",
                  }}
                />
              )
            })}
          </Carousel>
          <div className={classes.head}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className={classes.label}>{sku}</div>
              <Gap width={6} />
              {stok_available <= 0 && (
                <div className={classes.label}>Stok Habis</div>
              )}
            </div>
            <Typography>{product?.nama}</Typography>
          </div>
          <Divider />
          <div className={classes.priceWrapper}>
            <div className={classes.sectionPrice}>
              <Typography component="small" className={classes.titlePrice}>
                Harga
              </Typography>
              <br />
              <div style={{ display: "flex" }}>
                <div className={classes.descPrice}>
                  <b>{all_harga_str}</b>
                </div>
              </div>
            </div>
            <div className={classes.sectionPrice}>
              <Typography component="small" className={classes.titlePrice}>
                Stok Tersedia
              </Typography>
              <br />
              {(variant1 && variant2) || (!variant1 && !variant2) ? (
                <div className={classes.descPrice}>
                  <b>{stok_available > 0 ? stok_available : 0}</b>
                </div>
              ) : null}
            </div>
          </div>
          <Divider />
          <div className={classes.variantContent}>
            <Typography component="h3" className={classes.h3Title}>
              Pilih {product?.variant1_label}
            </Typography>
            {variant1Deps ? (
              <div className={classes.row}>
                {variant1Deps.map(item => {
                  const isSelected = variant1 === item.variant1_value
                  const stok = item.stok_available ? item.stok_available : 0
                  if (stok <= 0) {
                    return null
                  } else {
                    return (
                      <Button
                        key={item.variant1_value}
                        onClick={chooseVariantIDFromVariant1(
                          item.variant1_value,
                          item.id
                        )}
                        variant={isSelected ? "contained" : "outlined"}
                        classes={{ root: classes.btnt }}
                        color="primary"
                        className={classes.btnSelect}
                      >
                        {item.variant1_value?.toUpperCase?.() ??
                          item.variant1_value}
                      </Button>
                    )
                  }
                })}
              </div>
            ) : (
              <div className={classes.row}>
                {Object.keys(formattedVariants?.variant1_values ?? {}).map(
                  val => {
                    const deps = formattedVariants?.variant1_values[val]
                    const isSelected = variant1 === val
                    let stok = deps.reduce((prev, curr) => {
                      const stok = curr.stok_available ? curr.stok_available : 0
                      return prev + stok
                    }, 0)
                    if (stok <= 0) {
                      return null
                    } else {
                      return (
                        <Button
                          key={val}
                          onClick={handleChangeVariant1(val, deps)}
                          variant={isSelected ? "contained" : "outlined"}
                          classes={{ root: classes.btnt }}
                          color="primary"
                          className={classes.btnSelect}
                        >
                          {val?.toUpperCase?.() ?? val}
                        </Button>
                      )
                    }
                  }
                )}
              </div>
            )}
            <Gap height={16} />
            <Typography component="h3" className={classes.h3Title}>
              Pilih {product?.variant2_label}
            </Typography>
            {variant2Deps ? (
              <div className={classes.row}>
                {variant2Deps.map(item => {
                  const isSelected = variant2 === item.variant2_value
                  const stok = item.stok_available ? item.stok_available : 0
                  if (stok <= 0) {
                    return null
                  } else {
                    return (
                      <Button
                        key={item.variant2_value}
                        onClick={chooseVariantIDFromVariant2(
                          item.variant2_value,
                          item.id
                        )}
                        variant={isSelected ? "contained" : "outlined"}
                        classes={{ root: classes.btnt }}
                        color="primary"
                        className={classes.btnSelect}
                      >
                        {item.variant2_value?.toUpperCase?.() ??
                          item.variant2_value}
                      </Button>
                    )
                  }
                })}
              </div>
            ) : (
              <div className={classes.row}>
                {Object.keys(formattedVariants?.variant2_values ?? {}).map(
                  val => {
                    const deps = formattedVariants?.variant2_values[val]

                    const isSelected = variant2 === val
                    let stok = deps.reduce((prev, curr) => {
                      const stok = curr.stok_available ? curr.stok_available : 0
                      return prev + stok
                    }, 0)
                    if (stok <= 0) {
                      return null
                    } else {
                      return (
                        <Button
                          key={val}
                          onClick={handleChangeVariant2(val, deps)}
                          variant={isSelected ? "contained" : "outlined"}
                          classes={{ root: classes.btnt }}
                          color="primary"
                          className={classes.btnSelect}
                        >
                          {val?.toUpperCase?.() ?? val}
                        </Button>
                      )
                    }
                  }
                )}
              </div>
            )}
          </div>
          <Divider />
          <>
            <div className={classes.descHead}>
              <b>Deskripsi Produk</b>
            </div>
            <div className={classes.descBody}>{product?.deskripsi_lengkap}</div>
          </>
          <Divider />
          <div className={classes.btnWrapper}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              //   className={classes.btn}
              onClick={onClose}
            >
              Lihat Semua Produk
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}
export default DialogDetail
